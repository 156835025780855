<template>
  <div class="page">
    <div class="breadcrumbs">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item v-if="$route.query.type == 'home'" :to="{ path: '/' }">{{$t('nav.homePage')}}</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/newsList' }">{{$t('nav.compantNews')}}</el-breadcrumb-item>
        <el-breadcrumb-item>{{detail.title}}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="title">{{detail.title}}</div>
    <div class="date">{{detail.date}}</div>
    <div class="content" v-html="detail.content"></div>
  </div>
</template>

<script>
export default {
  data(){
    return {
      detail: ''
    }
  },
  created() {
    this.getDetail(this.$route.query.id)
  },
  methods: {
    getDetail (id) {
      let list = []
      switch (this.$i18n.locale) {
        case 'zh': // 中
          list = this.$config.newsListZH
          break
        case 'en': // 英
          list = this.$config.newsListEN
          break
      }
      list.forEach(item => {
        if (item._id == id) {
          this.detail = item
        }
      })
      console.log(this.detail)
    }
  }
}
</script>
<style scoped>
.page{
}
.breadcrumbs{
  display: flex;
  align-items: center;
  padding-left: 10px;
  height: 50px;
  background-color: #f6f6f6;
}
.title{
  margin-top: 60px;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
}
.date{
  padding: 25px 0 15px 0;
  text-align: center;
  font-size: 15px;
  color: #999;
  border-bottom: 1px solid #e9e9e9;
}
.content{
  padding-bottom: 100px;
  padding: 0 1px;
  width: 100%;
  box-sizing: border-box;
  overflow-x: hidden;
}
.content >>> p{
  line-height: 24px;
}
.content >>> img{
  margin-bottom: 20px;
  height: auto !important;
}
.content >>> #js_content img{
  margin-bottom: 0 !important;
}
</style>
<style>
.el-breadcrumb__item .el-breadcrumb__inner{
  color: #000 !important;
}
.el-breadcrumb__item .is-link{
  color: #999999 !important;
  font-weight: 400 !important;
}
.el-breadcrumb__item .is-link:hover{
  color: #14a6a3 !important;
}
</style>